import React, { useState, useEffect } from 'react';
import { Heading } from './Components/Catalyst/heading';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from './Components/Catalyst/table';
import { Input, InputGroup } from './Components/Catalyst/input';
import {
    ChevronDownIcon,
    MagnifyingGlassIcon,
    PlusIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ViolationCreate, ViolationRead } from './vio-api-client';
import { violationService } from './ApiClients';
import { Button } from './Components/Catalyst/button';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogTitle,
} from './Components/Catalyst/dialog';
import {
    Description,
    ErrorMessage,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from './Components/Catalyst/fieldset';
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
} from './Components/Catalyst/dropdown';

interface FormErrors {
    [key: string]: string | undefined;
}

interface TouchedFields {
    [key: string]: boolean;
}

interface ViolationListProps {
    hoaId?: number | null;
    homeId?: number | null;
    title?: string | null;
}

const initialViolationState: ViolationCreate = {
    home_id: 0,
    violation_type: 'Select Type',
    severity: 'Select Severity',
    description: '',
    message: '',
    date_of_violation: new Date().toISOString().split('T')[0],
    status: 'Pending',
    due_date: null,
    document_url: null,
    image_url: null,
    fine_amount: null,
};

const ViolationList: React.FC<ViolationListProps> = ({
    hoaId,
    homeId,
    title,
}) => {
    const navigate = useNavigate();
    const [violations, setViolations] = useState<ViolationRead[]>([]);
    const [filteredViolations, setFilteredViolations] = useState<
        ViolationRead[]
    >([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
    const [newViolation, setNewViolation] = useState<ViolationCreate>(
        initialViolationState
    );

    const severityOptions = ['Minor', 'Moderate', 'Major', 'Critical'];
    const typeOptions = [
        'Maintenance',
        'Noise',
        'Architectural',
        'Parking',
        'Landscaping',
        'Pets',
        'Trash',
        'Other',
    ];

    useEffect(() => {
        const fetchViolations = async () => {
            try {
                setLoading(true);
                let response;

                if (homeId && hoaId) {
                    // Fetch violations for specific home
                    response = await violationService.getViolationsByHome({
                        hoaId,
                        homeId,
                    });
                    setViolations(response.data);
                    setFilteredViolations(response.data);
                } else if (hoaId) {
                    // Fetch all violations for HOA
                    response = await violationService.getViolationsByHoa({
                        hoaId,
                    });
                    setViolations(response.data.violations);
                    setFilteredViolations(response.data.violations);
                } else {
                    console.log('getting all violations...');
                    response = await violationService.getAllViolations();
                    setViolations(response.data.violations);
                    setFilteredViolations(response.data.violations);
                }
                setError(null);
            } catch (err) {
                console.error('Error fetching violations:', err);
                setError(
                    `Failed to fetch violations${
                        homeId ? ' for this home' : ''
                    }. Please try again later.`
                );
            } finally {
                setLoading(false);
            }
        };

        fetchViolations();
    }, [hoaId, homeId]);

    useEffect(() => {
        const filtered = violations.filter(
            (violation) =>
                violation.description
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                violation.violation_type
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
        );
        setFilteredViolations(filtered);
    }, [searchTerm, violations]);

    const handleCreateViolation = () => {
        if (hoaId && homeId) {
            setNewViolation({ ...initialViolationState, home_id: homeId });
            setIsDialogOpen(true);
        }
    };

    const resetForm = () => {
        setNewViolation({ ...initialViolationState, home_id: homeId || 0 });
        setFormErrors({});
        setTouchedFields({});
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        resetForm();
    };

    const isValidUrl = (string: string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const validateField = (
        name: keyof ViolationCreate,
        value: any
    ): string | undefined => {
        switch (name) {
            case 'violation_type':
                return !typeOptions.includes(value)
                    ? 'Please select a valid violation type'
                    : '';
            case 'severity':
                return !severityOptions.includes(value)
                    ? 'Please select a valid severity level'
                    : '';
            case 'description':
                return !value
                    ? 'Description is required'
                    : value.length > 500
                      ? 'Description must be less than 500 characters'
                      : '';
            case 'message':
                return !value ? 'Message is required' : '';
            case 'fine_amount':
                if (!value) return '';
                return isNaN(value) || Number(value) < 0
                    ? 'Fine amount must be a positive number'
                    : '';
            case 'image_url':
            case 'document_url':
                if (!value) return '';
                return !isValidUrl(value)
                    ? 'Please enter a valid URL (e.g., https://example.com)'
                    : '';
            default:
                return '';
        }
    };

    const handleBlur = (
        e: React.FocusEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setTouchedFields((prev) => ({ ...prev, [name]: true }));

        const error = validateField(name as keyof ViolationCreate, value);
        setFormErrors((prev) => {
            const newErrors = { ...prev };
            if (error) {
                newErrors[name] = error;
            } else {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const handleInputChange = (
        e:
            | React.ChangeEvent<
                  HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
              >
            | { target: { name: string; value: string } }
    ) => {
        const { name, value } = e.target;
        setNewViolation((prev) => ({ ...prev, [name]: value }));
        setTouchedFields((prev) => ({ ...prev, [name]: true }));

        const error = validateField(name as keyof ViolationCreate, value);
        setFormErrors((prev) => {
            const newErrors = { ...prev };
            if (error) {
                newErrors[name] = error;
            } else {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const validateForm = (): boolean => {
        const errors: FormErrors = {};
        (Object.keys(newViolation) as Array<keyof ViolationCreate>).forEach(
            (key) => {
                const error = validateField(key, newViolation[key]);
                if (error) {
                    errors[key] = error;
                }
            }
        );
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSaveViolation = async () => {
        if (validateForm() && hoaId) {
            try {
                const response = await violationService.createViolation({
                    hoaId,
                    violationCreate: newViolation,
                });
                setViolations((prevViolations) => [
                    ...prevViolations,
                    response.data,
                ]);
                handleDialogClose();
            } catch (err) {
                console.error('Error creating violation:', err);
                setError('Failed to create violation. Please try again.');
            }
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleRowClick = (violation: ViolationRead) => {
        navigate(`/hoa/${violation.hoa_id}/violation/${violation.id}`);
    };

    const formatDate = (dateString: string | null | undefined) => {
        if (!dateString) return '-';
        return new Date(dateString).toLocaleDateString();
    };

    const getSeverityColor = (severity: string) => {
        switch (severity.toLowerCase()) {
            case 'high':
                return 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100';
            case 'medium':
                return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100';
            case 'low':
                return 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100';
            default:
                return 'bg-zinc-100 text-zinc-800 dark:bg-zinc-800 dark:text-zinc-100';
        }
    };

    const getStatusColor = (status: string) => {
        switch (status.toLowerCase()) {
            case 'open':
                return 'bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100';
            case 'resolved':
                return 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100';
            case 'in progress':
                return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100';
            default:
                return 'bg-zinc-100 text-zinc-800 dark:bg-zinc-800 dark:text-zinc-100';
        }
    };

    if (loading) {
        return <div>Loading violations...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <div className="flex justify-between items-center mb-4">
                <Heading level={4}>
                    {title || (homeId ? 'Home Violations' : 'Violations List')}
                </Heading>
                <div className="flex items-center space-x-4">
                    {hoaId && homeId && (
                        <Button onClick={handleCreateViolation}>
                            <PlusIcon className="h-5 w-5 mr-2" />
                            New Violation
                        </Button>
                    )}
                    <div className="w-64">
                        <InputGroup>
                            <MagnifyingGlassIcon data-slot="icon" />
                            <Input
                                type="search"
                                placeholder="Search violations..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </InputGroup>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-zinc-800 rounded-lg shadow transition-colors duration-200 overflow-hidden">
                <div className="px-4 py-5 sm:p-6">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full align-middle">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <div className="max-h-[500px] overflow-y-auto">
                                    <Table>
                                        <TableHead className="sticky top-0 z-10 bg-white dark:bg-zinc-800">
                                            <TableRow>
                                                <TableHeader>Type</TableHeader>
                                                <TableHeader>
                                                    Description
                                                </TableHeader>
                                                <TableHeader>
                                                    Severity
                                                </TableHeader>
                                                <TableHeader>Date</TableHeader>
                                                <TableHeader>
                                                    Due Date
                                                </TableHeader>
                                                <TableHeader>
                                                    Status
                                                </TableHeader>
                                                <TableHeader>
                                                    Fine Amount
                                                </TableHeader>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredViolations.map(
                                                (violation) => (
                                                    <TableRow
                                                        key={violation.id}
                                                        onClick={() =>
                                                            handleRowClick(
                                                                violation
                                                            )
                                                        }
                                                        className="cursor-pointer hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors duration-150"
                                                    >
                                                        <TableCell>
                                                            {
                                                                violation.violation_type
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                violation.description
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <span
                                                                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getSeverityColor(
                                                                    violation.severity
                                                                )}`}
                                                            >
                                                                {
                                                                    violation.severity
                                                                }
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatDate(
                                                                violation.date_of_violation
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatDate(
                                                                violation.due_date
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <span
                                                                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(
                                                                    violation.status
                                                                )}`}
                                                            >
                                                                {
                                                                    violation.status
                                                                }
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            {violation.fine_amount
                                                                ? `$${violation.fine_amount.toFixed(2)}`
                                                                : '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Add New Violation</DialogTitle>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label
                                    htmlFor="violation_type"
                                    className="mr-4"
                                >
                                    Violation Type
                                </Label>
                                <Description>required</Description>
                                <Dropdown>
                                    <DropdownButton outline>
                                        {newViolation?.violation_type ||
                                            'Select Type'}
                                        <ChevronDownIcon className="h-5 w-5 ml-2" />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {typeOptions.map((type) => (
                                            <DropdownItem
                                                key={type}
                                                onClick={() =>
                                                    handleInputChange({
                                                        target: {
                                                            name: 'violation_type',
                                                            value: type,
                                                        },
                                                    } as any)
                                                }
                                            >
                                                {type}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                {touchedFields.violation_type &&
                                    formErrors.violation_type && (
                                        <ErrorMessage>
                                            {formErrors.violation_type}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            <Field>
                                <Label htmlFor="severity" className="mr-4">
                                    Severity
                                </Label>
                                <Description>required</Description>
                                <Dropdown>
                                    <DropdownButton outline>
                                        {newViolation?.severity ||
                                            'Select Severity'}
                                        <ChevronDownIcon className="h-5 w-5 ml-2" />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {severityOptions.map((severity) => (
                                            <DropdownItem
                                                key={severity}
                                                onClick={() =>
                                                    handleInputChange({
                                                        target: {
                                                            name: 'severity',
                                                            value: severity,
                                                        },
                                                    } as any)
                                                }
                                            >
                                                {severity}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                {touchedFields.severity &&
                                    formErrors.severity && (
                                        <ErrorMessage>
                                            {formErrors.severity}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            <Field>
                                <Label htmlFor="date_of_violation">
                                    Date of Violation
                                </Label>
                                <Description>required</Description>
                                <Input
                                    id="date_of_violation"
                                    name="date_of_violation"
                                    type="date"
                                    value={newViolation.date_of_violation}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    required
                                    data-invalid={
                                        touchedFields.date_of_violation &&
                                        formErrors.date_of_violation
                                    }
                                />
                                {touchedFields.date_of_violation &&
                                    formErrors.date_of_violation && (
                                        <ErrorMessage>
                                            {formErrors.date_of_violation}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            <Field>
                                <Label htmlFor="description">Description</Label>
                                <Description>
                                    required (max 500 characters)
                                </Description>
                                <Input
                                    id="description"
                                    name="description"
                                    value={newViolation.description}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Brief description of the violation"
                                    required
                                    data-invalid={
                                        touchedFields.description &&
                                        formErrors.description
                                    }
                                />
                                {touchedFields.description &&
                                    formErrors.description && (
                                        <ErrorMessage>
                                            {formErrors.description}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            <Field>
                                <Label htmlFor="message">
                                    Detailed Message
                                </Label>
                                <Description>required</Description>
                                <Input
                                    id="message"
                                    name="message"
                                    value={newViolation.message}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Detailed description of the violation"
                                    required
                                    data-invalid={
                                        touchedFields.message &&
                                        formErrors.message
                                    }
                                />
                                {touchedFields.message &&
                                    formErrors.message && (
                                        <ErrorMessage>
                                            {formErrors.message}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            <Field>
                                <Label htmlFor="fine_amount">Fine Amount</Label>
                                <Input
                                    id="fine_amount"
                                    name="fine_amount"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    value={newViolation.fine_amount || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter fine amount (optional)"
                                />
                            </Field>

                            <Field>
                                <Label htmlFor="due_date">Due Date</Label>
                                <Input
                                    id="due_date"
                                    name="due_date"
                                    type="date"
                                    value={
                                        newViolation.due_date?.split('T')[0] ||
                                        ''
                                    }
                                    onChange={handleInputChange}
                                />
                            </Field>

                            {/* Image URL */}
                            <Field>
                                <Label>Image URL</Label>
                                <Input
                                    name="image_url"
                                    type="url"
                                    value={newViolation?.image_url || ''}
                                    onChange={handleInputChange}
                                    placeholder="https://example.com/image.jpg"
                                    pattern="https?://.*"
                                    data-invalid={
                                        touchedFields.image_url &&
                                        formErrors.image_url
                                    }
                                />
                                {touchedFields.image_url &&
                                    formErrors.image_url && (
                                        <ErrorMessage>
                                            {formErrors.image_url}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            {/* Document URL */}
                            <Field>
                                <Label>Document URL</Label>
                                <Input
                                    name="document_url"
                                    type="url"
                                    value={newViolation?.document_url || ''}
                                    onChange={handleInputChange}
                                    placeholder="https://example.com/document.pdf"
                                    pattern="https?://.*"
                                    data-invalid={
                                        touchedFields.document_url &&
                                        formErrors.document_url
                                    }
                                />
                                {touchedFields.document_url &&
                                    formErrors.document_url && (
                                        <ErrorMessage>
                                            {formErrors.document_url}
                                        </ErrorMessage>
                                    )}
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="zinc">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveViolation} color="blue">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ViolationList;
