import React, { useState, useEffect } from 'react';
import { Heading } from './Components/Catalyst/heading';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from './Components/Catalyst/table';
import { HomeSchema, HomeCreateOrUpdateSchema } from './hoa-api-client';
import { homeService } from './ApiClients';
import { Input, InputGroup } from './Components/Catalyst/input';
import {
    ChevronDownIcon,
    MagnifyingGlassIcon,
    PlusIcon,
} from '@heroicons/react/24/outline';
import { Button } from './Components/Catalyst/button';
import {
    Dialog,
    DialogTitle,
    DialogBody,
    DialogActions,
} from './Components/Catalyst/dialog';
import {
    Description,
    ErrorMessage,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from './Components/Catalyst/fieldset';
import { useNavigate } from 'react-router-dom';
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
} from './Components/Catalyst/dropdown';

interface FormErrors {
    [key: string]: string | undefined;
}

const initialHomeState: HomeCreateOrUpdateSchema = {
    owners: [],
    hoa_id: 0,
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    email: '',
    phone: '',
    property_type: 'Select Type',
    status: 'Select Status',
};

interface TouchedFields {
    [key: string]: boolean;
}

const HomeList: React.FC<{ hoaId: number }> = ({ hoaId }) => {
    const navigate = useNavigate();
    const [homes, setHomes] = useState<HomeSchema[]>([]);
    const [filteredHomes, setFilteredHomes] = useState<HomeSchema[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newHome, setNewHome] =
        useState<HomeCreateOrUpdateSchema>(initialHomeState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
    const [currentOwner, setCurrentOwner] = useState({
        first_name: '',
        last_name: '',
    });

    useEffect(() => {
        const fetchHomes = async () => {
            try {
                setLoading(true);
                const response = await homeService.getHomesByHoa({ hoaId });
                setHomes(response.data);
                setFilteredHomes(response.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching homes:', err);
                setError('Failed to fetch homes. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchHomes();
    }, [hoaId]);

    useEffect(() => {
        const filtered = homes.filter((home) => {
            const fullAddress = getFullAddress(home).toLowerCase();
            const ownerNames = home.owners
                .map((owner) =>
                    `${owner.first_name} ${owner.last_name}`.toLowerCase()
                )
                .join(' ');
            const searchTermLower = searchTerm.toLowerCase();

            return (
                fullAddress.includes(searchTermLower) ||
                ownerNames.includes(searchTermLower)
            );
        });
        setFilteredHomes(filtered);
    }, [searchTerm, homes]);

    const getFullAddress = (home: HomeSchema): string => {
        const parts = [
            home.street,
            home.street2,
            home.city,
            home.state,
            home.zip,
            home.country,
        ].filter(Boolean);
        return parts.join(', ');
    };

    const handleRowClick = (home: HomeSchema) => {
        navigate(`/hoa/${hoaId}/home/${home.id}`);
    };

    const handleAddHome = () => {
        setIsDialogOpen(true);
    };

    const resetForm = () => {
        setNewHome({ ...initialHomeState, hoa_id: hoaId });
        setFormErrors({});
        setTouchedFields({});
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        resetForm();
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const validateField = (
        name: keyof HomeCreateOrUpdateSchema,
        value: HomeCreateOrUpdateSchema[keyof HomeCreateOrUpdateSchema]
    ): string | undefined => {
        let error: string | undefined;
        switch (name) {
            case 'street':
            case 'city':
            case 'state':
            case 'zip':
                error = !value
                    ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
                    : undefined;
                break;
            case 'email':
                if (
                    value &&
                    typeof value === 'string' &&
                    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
                ) {
                    error = 'Invalid email format';
                }
                break;
            case 'phone':
                if (
                    value &&
                    typeof value === 'string' &&
                    !/^\d{10}$/.test(value)
                ) {
                    error = 'Phone number must be 10 digits';
                }
                break;
            case 'owners':
                if (!Array.isArray(value) || value.length === 0) {
                    error = 'At least one owner is required';
                } else {
                    const invalidOwners = value.filter(
                        (owner) => !owner.first_name || !owner.last_name
                    );
                    if (invalidOwners.length > 0) {
                        error =
                            'All owners must have both first and last names';
                    }
                }
                break;
            case 'property_type':
            case 'status':
                if (
                    !value ||
                    value === 'Select Type' ||
                    value === 'Select Status'
                ) {
                    error = `${name.charAt(0).toUpperCase() + name.slice(1).replace('_', ' ')} is required`;
                }
                break;
        }
        return error;
    };

    const handleBlur = (
        e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setTouchedFields((prev) => ({ ...prev, [name]: true }));

        const error = validateField(
            name as keyof HomeCreateOrUpdateSchema,
            value
        );
        setFormErrors((prev) => {
            const newErrors = { ...prev };
            if (error) {
                newErrors[name] = error;
            } else {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const validateForm = (): boolean => {
        const errors: FormErrors = {};
        (Object.keys(newHome) as Array<keyof HomeCreateOrUpdateSchema>).forEach(
            (key) => {
                const error = validateField(key, newHome[key]);
                if (error && key !== 'hoa_id') {
                    errors[key] = error;
                }
            }
        );
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleInputChange = (
        e:
            | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            | { target: { name: string; value: string } }
    ) => {
        const { name, value } = e.target;
        setNewHome((prev) => ({ ...prev, [name]: value }));
        setTouchedFields((prev) => ({ ...prev, [name]: true }));

        const error = validateField(
            name as keyof HomeCreateOrUpdateSchema,
            value
        );
        setFormErrors((prev) => {
            const newErrors = { ...prev };
            if (error) {
                newErrors[name] = error;
            } else {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const handleOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCurrentOwner((prev) => ({ ...prev, [name]: value }));
    };

    const addOwner = () => {
        if (currentOwner.first_name && currentOwner.last_name) {
            setNewHome((prev) => {
                const updatedOwners = [...prev.owners, currentOwner];
                const error = validateField('owners', updatedOwners);
                setFormErrors((prevErrors) => {
                    if (error) {
                        return { ...prevErrors, owners: error };
                    } else {
                        const { owners, ...rest } = prevErrors;
                        return rest;
                    }
                });
                return { ...prev, owners: updatedOwners };
            });
            setCurrentOwner({ first_name: '', last_name: '' });
        }
    };

    const removeOwner = (index: number) => {
        setNewHome((prev) => {
            const updatedOwners = prev.owners.filter((_, i) => i !== index);
            const error = validateField('owners', updatedOwners);
            setFormErrors((prevErrors) => {
                if (error) {
                    return { ...prevErrors, owners: error };
                } else {
                    const { owners, ...rest } = prevErrors;
                    return rest;
                }
            });
            return { ...prev, owners: updatedOwners };
        });
    };

    const handleSaveHome = async () => {
        if (validateForm()) {
            try {
                const response = await homeService.createHome({
                    homeCreateOrUpdateSchema: { ...newHome, hoa_id: hoaId },
                });
                setHomes((prevHomes) => [...prevHomes, response.data]);
                handleDialogClose();
            } catch (err) {
                console.error('Error creating home:', err);
            }
        }
    };

    if (loading) {
        return <div>Loading homes...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <div className="flex justify-between items-center mb-4">
                <Heading level={4}>Home List</Heading>
                <div className="flex items-center space-x-4">
                    <Button onClick={handleAddHome}>
                        <PlusIcon className="h-5 w-5 mr-2" />
                        Add Home
                    </Button>
                    <div className="w-64">
                        <InputGroup>
                            <MagnifyingGlassIcon data-slot="icon" />
                            <Input
                                type="search"
                                placeholder="Search by address or name..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </InputGroup>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-zinc-800 rounded-lg shadow transition-colors duration-200 overflow-hidden">
                <div className="px-4 py-5 sm:p-6">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full align-middle">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <div className="max-h-[500px] overflow-y-auto">
                                    <Table>
                                        <TableHead className="sticky top-0 z-10 bg-white dark:bg-zinc-800">
                                            <TableRow>
                                                <TableHeader>
                                                    Address
                                                </TableHeader>
                                                <TableHeader>
                                                    Property Type
                                                </TableHeader>
                                                <TableHeader>
                                                    Status
                                                </TableHeader>
                                                <TableHeader>
                                                    Owners
                                                </TableHeader>
                                                <TableHeader>
                                                    Contact
                                                </TableHeader>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredHomes.map((home) => (
                                                <TableRow
                                                    key={home.id}
                                                    onClick={() =>
                                                        handleRowClick(home)
                                                    }
                                                    className="cursor-pointer hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors duration-150"
                                                >
                                                    <TableCell>
                                                        {getFullAddress(home)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {home.property_type}
                                                    </TableCell>
                                                    <TableCell>
                                                        {home.status}
                                                    </TableCell>
                                                    <TableCell>
                                                        {home.owners.map(
                                                            (owner, index) => (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    {
                                                                        owner.first_name
                                                                    }{' '}
                                                                    {
                                                                        owner.last_name
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {home.email && (
                                                            <div>
                                                                {home.email}
                                                            </div>
                                                        )}
                                                        {home.phone && (
                                                            <div>
                                                                {home.phone}
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Add New Home</DialogTitle>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>Owners</Label>
                                <Description>
                                    at least one is required
                                </Description>
                                <div className="flex space-x-2 mb-2">
                                    <Input
                                        name="first_name"
                                        value={currentOwner.first_name}
                                        onChange={handleOwnerChange}
                                        placeholder="First Name"
                                    />
                                    <Input
                                        name="last_name"
                                        value={currentOwner.last_name}
                                        onChange={handleOwnerChange}
                                        placeholder="Last Name"
                                    />
                                    <Button
                                        outline
                                        onClick={addOwner}
                                        type="button"
                                    >
                                        Add
                                    </Button>
                                </div>
                                {newHome.owners.map((owner, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center space-x-2 mb-2"
                                    >
                                        <Label>
                                            {owner.first_name} {owner.last_name}
                                        </Label>
                                        <Button
                                            onClick={() => removeOwner(index)}
                                            type="button"
                                            color="red"
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                ))}
                                {touchedFields.owners && formErrors.owners && (
                                    <ErrorMessage>
                                        {formErrors.owners}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="street">Street</Label>
                                <Description>required</Description>
                                <Input
                                    id="street"
                                    name="street"
                                    value={newHome.street}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter street address"
                                    required
                                    data-invalid={
                                        touchedFields.street &&
                                        formErrors.street
                                    }
                                />
                                {touchedFields.street && formErrors.street && (
                                    <ErrorMessage>
                                        {formErrors.street}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="street2">Street 2</Label>
                                <Input
                                    id="street2"
                                    name="street2"
                                    value={newHome.street2 || ''}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter additional street information (optional)"
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="city">City</Label>
                                <Description>required</Description>
                                <Input
                                    id="city"
                                    name="city"
                                    value={newHome.city}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter city"
                                    required
                                    data-invalid={
                                        touchedFields.city && formErrors.city
                                    }
                                />
                                {touchedFields.city && formErrors.city && (
                                    <ErrorMessage>
                                        {formErrors.city}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="state">State</Label>
                                <Description>required</Description>
                                <Input
                                    id="state"
                                    name="state"
                                    value={newHome.state}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter state"
                                    required
                                    data-invalid={
                                        touchedFields.state && formErrors.state
                                    }
                                />
                                {touchedFields.state && formErrors.state && (
                                    <ErrorMessage>
                                        {formErrors.state}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="zip">ZIP Code</Label>
                                <Description>required</Description>
                                <Input
                                    id="zip"
                                    name="zip"
                                    value={newHome.zip}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter ZIP code"
                                    required
                                    data-invalid={
                                        touchedFields.zip && formErrors.zip
                                    }
                                />
                                {touchedFields.zip && formErrors.zip && (
                                    <ErrorMessage>
                                        {formErrors.zip}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="country">Country</Label>
                                <Input
                                    id="country"
                                    name="country"
                                    value={newHome.country || ''}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter country"
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="email">Contact Email</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={newHome.email || ''}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter contact email"
                                    data-invalid={
                                        touchedFields.email && formErrors.email
                                    }
                                />
                                {touchedFields.email && formErrors.email && (
                                    <ErrorMessage>
                                        {formErrors.email}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="phone">Contact Phone</Label>
                                <Input
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    value={newHome.phone || ''}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter contact phone"
                                    data-invalid={
                                        touchedFields.phone && formErrors.phone
                                    }
                                />
                                {touchedFields.phone && formErrors.phone && (
                                    <ErrorMessage>
                                        {formErrors.phone}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="property_type" className="mr-4">
                                    Property Type
                                </Label>
                                <Dropdown>
                                    <DropdownButton outline>
                                        {newHome.property_type ||
                                            'Select Property Type'}
                                        <ChevronDownIcon />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {[
                                            'Single Family',
                                            'Townhouse',
                                            'Condo',
                                            'Apartment',
                                            'Other',
                                        ].map((type) => (
                                            <DropdownItem
                                                key={type}
                                                onClick={() =>
                                                    handleInputChange({
                                                        target: {
                                                            name: 'property_type',
                                                            value: type,
                                                        },
                                                    } as any)
                                                }
                                            >
                                                {type}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                {touchedFields.property_type &&
                                    formErrors.property_type && (
                                        <ErrorMessage>
                                            {formErrors.property_type}
                                        </ErrorMessage>
                                    )}
                            </Field>
                            <Field>
                                <Label htmlFor="status" className="mr-4">
                                    Status
                                </Label>
                                <Dropdown>
                                    <DropdownButton outline>
                                        {newHome.status || 'Select Status'}
                                        <ChevronDownIcon />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {[
                                            'Owner Occupied',
                                            'Renter Occupied',
                                            'Vacant',
                                            'For Sale',
                                            'For Rent',
                                            'Unknown',
                                        ].map((status) => (
                                            <DropdownItem
                                                key={status}
                                                onClick={() =>
                                                    handleInputChange({
                                                        target: {
                                                            name: 'status',
                                                            value: status,
                                                        },
                                                    } as any)
                                                }
                                            >
                                                {status}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                {touchedFields.status && formErrors.status && (
                                    <ErrorMessage>
                                        {formErrors.status}
                                    </ErrorMessage>
                                )}
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="zinc">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveHome} color="blue">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default HomeList;
