import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppSidebar from './AppSidebar';
import { Navbar } from './Components/Catalyst/navbar';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import { Heading, Subheading } from './Components/Catalyst/heading';
import { Divider } from './Components/Catalyst/divider';
import { Button } from './Components/Catalyst/button';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogTitle,
} from './Components/Catalyst/dialog';
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
    ErrorMessage,
} from './Components/Catalyst/fieldset';
import { Input } from './Components/Catalyst/input';
import { violationService } from './ApiClients';
import { ViolationRead, ViolationUpdate } from './vio-api-client';
import { formatDate } from './utils/dateUtils';
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
} from './Components/Catalyst/dropdown';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

interface TouchedFields {
    [key: string]: boolean;
}

interface FormErrors {
    [key: string]: string;
}

const ViolationDetailPage: React.FC = () => {
    const { hoaId, violationId } = useParams<{
        hoaId: string;
        violationId: string;
    }>();
    const [loading, setLoading] = useState(true);
    const [violation, setViolation] = useState<ViolationRead | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedViolation, setEditedViolation] =
        useState<ViolationUpdate | null>(null);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
    const [isSaving, setIsSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const severityOptions = ['Minor', 'Moderate', 'Major', 'Critical'];
    const statusOptions = [
        'Pending',
        'In Progress',
        'Resolved',
        'Escalated',
        'Appealed',
    ];
    const typeOptions = [
        'Maintenance',
        'Noise',
        'Architectural',
        'Parking',
        'Landscaping',
        'Pets',
        'Trash',
        'Other',
    ];

    useEffect(() => {
        const fetchViolation = async () => {
            try {
                setLoading(true);
                const response = await violationService.getViolation({
                    hoaId: Number(hoaId),
                    violationId: Number(violationId),
                });
                setViolation(response.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching violation:', err);
                setError(
                    `Failed to fetch violation details. Please try again later.`
                );
            } finally {
                setLoading(false);
            }
        };

        fetchViolation();
    }, [hoaId, violationId]);

    const handleEdit = () => {
        if (violation) {
            const {
                id,
                date_created,
                date_updated,
                home_id,
                created_by,
                ...editableFields
            } = violation;
            setEditedViolation(editableFields as ViolationUpdate);
            setIsEditing(true);
            setTouchedFields({});
            setFormErrors({});
        }
    };

    const isValidUrl = (string: string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const handleSave = async () => {
        if (!editedViolation || !violation) return;

        setIsSaving(true);
        setError(null);
        setSuccessMessage(null);

        // Create a clean update payload
        const updatePayload: ViolationUpdate = {};

        // Only include fields that have changed
        if (editedViolation.violation_type !== violation.violation_type) {
            updatePayload.violation_type =
                editedViolation.violation_type?.toLowerCase();
        }

        if (editedViolation.severity !== violation.severity) {
            updatePayload.severity = editedViolation.severity?.toLowerCase();
        }

        if (editedViolation.description !== violation.description) {
            updatePayload.description = editedViolation.description;
        }

        if (editedViolation.message !== violation.message) {
            updatePayload.message = editedViolation.message;
        }

        if (editedViolation.status !== violation.status) {
            updatePayload.status = editedViolation.status
                ?.toLowerCase()
                .replace(' ', '_');
        }

        // Handle dates
        if (editedViolation.due_date) {
            updatePayload.due_date = new Date(
                editedViolation.due_date
            ).toISOString();
        }

        if (editedViolation.follow_up_date) {
            updatePayload.follow_up_date = new Date(
                editedViolation.follow_up_date
            ).toISOString();
        }

        if (editedViolation.fine_paid_date) {
            updatePayload.fine_paid_date = new Date(
                editedViolation.fine_paid_date
            ).toISOString();
        }

        // Handle URLs - only include if they've changed and are valid URLs
        if (
            editedViolation.image_url &&
            editedViolation.image_url !== violation.image_url
        ) {
            try {
                new URL(editedViolation.image_url); // validate URL
                updatePayload.image_url = editedViolation.image_url;
            } catch (e) {
                console.error('Invalid image URL');
            }
        }

        if (
            editedViolation.document_url &&
            editedViolation.document_url !== violation.document_url
        ) {
            try {
                new URL(editedViolation.document_url); // validate URL
                updatePayload.document_url = editedViolation.document_url;
            } catch (e) {
                console.error('Invalid document URL');
            }
        }

        // Handle numeric values
        if (editedViolation.fine_amount !== violation.fine_amount) {
            updatePayload.fine_amount = Number(editedViolation.fine_amount);
        }

        try {
            await violationService.updateViolation({
                hoaId: Number(hoaId),
                violationId: Number(violationId),
                violationUpdate: updatePayload,
            });

            // Update local state
            setViolation({
                ...violation,
                ...updatePayload,
            } as ViolationRead);

            setIsEditing(false);
            setSuccessMessage('Violation updated successfully!');
            setTimeout(() => setSuccessMessage(null), 5000);
        } catch (err) {
            console.error('Error updating violation:', err);
            setError('Failed to update violation. Please try again later.');
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedViolation(null);
        setFormErrors({});
        setTouchedFields({});
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!editedViolation) return;
        const { name, value } = e.target;
        if (name === 'image_url' || name === 'document_url') {
            setEditedViolation({
                ...editedViolation,
                [name]: value.toString(), // Ensure we're sending a string
            });
        } else {
            setEditedViolation({ ...editedViolation, [name]: value });
        }
        setTouchedFields({ ...touchedFields, [name]: true });
        const error = validateField(name, value);
        setFormErrors((prev) => ({ ...prev, [name]: error }));
    };

    const validateField = (name: string, value: any): string => {
        switch (name) {
            case 'violation_type':
                return !typeOptions.includes(value)
                    ? 'Please select a valid violation type'
                    : '';
            case 'severity':
                return !severityOptions.includes(value)
                    ? 'Please select a valid severity level'
                    : '';
            case 'status':
                return !statusOptions.includes(value)
                    ? 'Please select a valid status'
                    : '';
            case 'description':
                return !value
                    ? 'Description is required'
                    : value.length > 500
                      ? 'Description must be less than 500 characters'
                      : '';
            case 'message':
                return !value ? 'Message is required' : '';
            case 'fine_amount':
                if (!value) return '';
                return isNaN(value) || Number(value) < 0
                    ? 'Fine amount must be a positive number'
                    : '';
            case 'image_url':
            case 'document_url':
                if (!value) return '';
                return !isValidUrl(value)
                    ? 'Please enter a valid URL (e.g., https://example.com)'
                    : '';
            default:
                return '';
        }
    };

    if (loading) {
        return <div>Loading violation details...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!violation) {
        return <div>No violation found with the given ID.</div>;
    }

    return (
        <SidebarLayout
            sidebar={<AppSidebar currentPage={'violations'} />}
            navbar={<Navbar>{/* Your navbar content */}</Navbar>}
        >
            <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <Heading level={2}>Violation Details</Heading>
                    </div>
                    <Button onClick={handleEdit}>Edit Violation</Button>
                </div>

                <Divider className="my-6" />

                {successMessage && (
                    <div className="mt-4 p-4 bg-green-100 text-green-700 rounded">
                        {successMessage}
                    </div>
                )}

                {error && (
                    <div className="mt-4 p-4 bg-red-100 text-red-700 rounded">
                        {error}
                    </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Basic Information */}
                    <div className="bg-white dark:bg-zinc-800 p-6 rounded-lg shadow">
                        <h3 className="text-lg font-semibold mb-4 text-zinc-900 dark:text-white">
                            Basic Information
                        </h3>
                        <dl className="space-y-4">
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Type
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {violation.violation_type}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Severity
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {violation.severity}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Status
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {violation.status}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Description
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {violation.description}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Message
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {violation.message}
                                </dd>
                            </div>
                        </dl>
                    </div>

                    {/* Dates */}
                    <div className="bg-white dark:bg-zinc-800 p-6 rounded-lg shadow">
                        <h3 className="text-lg font-semibold mb-4 text-zinc-900 dark:text-white">
                            Important Dates
                        </h3>
                        <dl className="space-y-4">
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Date of Violation
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {formatDate(violation.date_of_violation)}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Due Date
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {formatDate(violation.due_date)}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Resolution Date
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {formatDate(violation.resolution_date)}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Follow-up Date
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {formatDate(violation.follow_up_date)}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Created Date
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {formatDate(violation.date_created)}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Last Updated
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {formatDate(violation.date_updated)}
                                </dd>
                            </div>
                        </dl>
                    </div>

                    {/* Fine Information */}
                    <div className="bg-white dark:bg-zinc-800 p-6 rounded-lg shadow">
                        <h3 className="text-lg font-semibold mb-4 text-zinc-900 dark:text-white">
                            Fine Information
                        </h3>
                        <dl className="space-y-4">
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Fine Amount
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {violation.fine_amount
                                        ? `$${violation.fine_amount.toFixed(2)}`
                                        : 'N/A'}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Fine Paid Date
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {formatDate(violation.fine_paid_date)}
                                </dd>
                            </div>
                        </dl>
                    </div>

                    {/* Additional Information */}
                    <div className="bg-white dark:bg-zinc-800 p-6 rounded-lg shadow">
                        <h3 className="text-lg font-semibold mb-4 text-zinc-900 dark:text-white">
                            Additional Information
                        </h3>
                        <dl className="space-y-4">
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    HOA ID
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {violation.hoa_id}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Home ID
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {violation.home_id}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Created By
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {violation.created_by}
                                </dd>
                            </div>
                            <div>
                                <dt className="text-sm font-medium text-zinc-500">
                                    Updated By
                                </dt>
                                <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                    {violation.updated_by || 'N/A'}
                                </dd>
                            </div>
                            {violation.image_url && (
                                <div>
                                    <dt className="text-sm font-medium text-zinc-500">
                                        Image URL
                                    </dt>
                                    <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                        {violation.image_url}
                                    </dd>
                                </div>
                            )}
                            {violation.document_url && (
                                <div>
                                    <dt className="text-sm font-medium text-zinc-500">
                                        Document URL
                                    </dt>
                                    <dd className="mt-1 text-sm text-zinc-900 dark:text-white">
                                        {violation.document_url}
                                    </dd>
                                </div>
                            )}
                        </dl>
                    </div>
                </div>
            </div>

            <Dialog open={isEditing} onClose={() => setIsEditing(false)}>
                <DialogTitle>Edit Violation</DialogTitle>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>Violation Type</Label>
                                <Description>required</Description>
                                <Dropdown>
                                    <DropdownButton outline>
                                        {editedViolation?.violation_type ||
                                            'Select Type'}
                                        <ChevronDownIcon className="h-5 w-5 ml-2" />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {typeOptions.map((type) => (
                                            <DropdownItem
                                                key={type}
                                                onClick={() =>
                                                    handleInputChange({
                                                        target: {
                                                            name: 'violation_type',
                                                            value: type,
                                                        },
                                                    } as any)
                                                }
                                            >
                                                {type}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                {touchedFields.violation_type &&
                                    formErrors.violation_type && (
                                        <ErrorMessage>
                                            {formErrors.violation_type}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            <Field>
                                <Label>Severity</Label>
                                <Description>required</Description>
                                <Dropdown>
                                    <DropdownButton outline>
                                        {editedViolation?.severity ||
                                            'Select Severity'}
                                        <ChevronDownIcon className="h-5 w-5 ml-2" />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {severityOptions.map((severity) => (
                                            <DropdownItem
                                                key={severity}
                                                onClick={() =>
                                                    handleInputChange({
                                                        target: {
                                                            name: 'severity',
                                                            value: severity,
                                                        },
                                                    } as any)
                                                }
                                            >
                                                {severity}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                {touchedFields.severity &&
                                    formErrors.severity && (
                                        <ErrorMessage>
                                            {formErrors.severity}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            <Field>
                                <Label>Status</Label>
                                <Description>required</Description>
                                <Dropdown>
                                    <DropdownButton outline>
                                        {editedViolation?.status ||
                                            'Select Status'}
                                        <ChevronDownIcon className="h-5 w-5 ml-2" />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {statusOptions.map((status) => (
                                            <DropdownItem
                                                key={status}
                                                onClick={() =>
                                                    handleInputChange({
                                                        target: {
                                                            name: 'status',
                                                            value: status,
                                                        },
                                                    } as any)
                                                }
                                            >
                                                {status}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                {touchedFields.status && formErrors.status && (
                                    <ErrorMessage>
                                        {formErrors.status}
                                    </ErrorMessage>
                                )}
                            </Field>

                            {/* Description */}
                            <Field>
                                <Label>Description</Label>
                                <Description>required</Description>
                                <Input
                                    name="description"
                                    value={editedViolation?.description || ''}
                                    onChange={handleInputChange}
                                    data-invalid={
                                        touchedFields.description &&
                                        formErrors.description
                                    }
                                />
                                {touchedFields.description &&
                                    formErrors.description && (
                                        <ErrorMessage>
                                            {formErrors.description}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            {/* Message */}
                            <Field>
                                <Label>Message</Label>
                                <Description>required</Description>
                                <Input
                                    name="message"
                                    value={editedViolation?.message || ''}
                                    onChange={handleInputChange}
                                    data-invalid={
                                        touchedFields.message &&
                                        formErrors.message
                                    }
                                />
                                {touchedFields.message &&
                                    formErrors.message && (
                                        <ErrorMessage>
                                            {formErrors.message}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            {/* Due Date */}
                            <Field>
                                <Label>Due Date</Label>
                                <Input
                                    type="date"
                                    name="due_date"
                                    value={
                                        editedViolation?.due_date?.split(
                                            'T'
                                        )[0] || ''
                                    }
                                    onChange={handleInputChange}
                                />
                            </Field>

                            {/* Follow-up Date */}
                            <Field>
                                <Label>Follow-up Date</Label>
                                <Input
                                    type="date"
                                    name="follow_up_date"
                                    value={
                                        editedViolation?.follow_up_date?.split(
                                            'T'
                                        )[0] || ''
                                    }
                                    onChange={handleInputChange}
                                />
                            </Field>

                            {/* Fine Amount */}
                            <Field>
                                <Label>Fine Amount</Label>
                                <Input
                                    type="number"
                                    name="fine_amount"
                                    value={editedViolation?.fine_amount || ''}
                                    onChange={handleInputChange}
                                    data-invalid={
                                        touchedFields.fine_amount &&
                                        formErrors.fine_amount
                                    }
                                />
                                {touchedFields.fine_amount &&
                                    formErrors.fine_amount && (
                                        <ErrorMessage>
                                            {formErrors.fine_amount}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            {/* Fine Paid Date */}
                            <Field>
                                <Label>Fine Paid Date</Label>
                                <Input
                                    type="date"
                                    name="fine_paid_date"
                                    value={
                                        editedViolation?.fine_paid_date?.split(
                                            'T'
                                        )[0] || ''
                                    }
                                    onChange={handleInputChange}
                                />
                            </Field>

                            {/* Image URL */}
                            <Field>
                                <Label>Image URL</Label>
                                <Input
                                    name="image_url"
                                    type="url"
                                    value={editedViolation?.image_url || ''}
                                    onChange={handleInputChange}
                                    placeholder="https://example.com/image.jpg"
                                    pattern="https?://.*"
                                    data-invalid={
                                        touchedFields.image_url &&
                                        formErrors.image_url
                                    }
                                />
                                {touchedFields.image_url &&
                                    formErrors.image_url && (
                                        <ErrorMessage>
                                            {formErrors.image_url}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            {/* Document URL */}
                            <Field>
                                <Label>Document URL</Label>
                                <Input
                                    name="document_url"
                                    type="url"
                                    value={editedViolation?.document_url || ''}
                                    onChange={handleInputChange}
                                    placeholder="https://example.com/document.pdf"
                                    pattern="https?://.*"
                                    data-invalid={
                                        touchedFields.document_url &&
                                        formErrors.document_url
                                    }
                                />
                                {touchedFields.document_url &&
                                    formErrors.document_url && (
                                        <ErrorMessage>
                                            {formErrors.document_url}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            {/* Add more fields for description, message, status, fine_amount etc */}
                            {/* Similar to the above pattern */}
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <DialogActions>
                    <Button
                        onClick={handleCancel}
                        color="zinc"
                        disabled={isSaving}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="blue"
                        disabled={isSaving}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </SidebarLayout>
    );
};

export default ViolationDetailPage;
