import AppSidebar from './AppSidebar';
import { Divider } from './Components/Catalyst/divider';
import { Heading, Subheading } from './Components/Catalyst/heading';
import { Navbar } from './Components/Catalyst/navbar';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import HOAList from './HOAList';

interface HOAPageProps {
    currentPage: string;
}

const HOAPage: React.FC<HOAPageProps> = ({ currentPage }) => {
    return (
        <SidebarLayout
            sidebar={<AppSidebar currentPage={currentPage} />}
            navbar={<Navbar>{/* Your navbar content */}</Navbar>}
        >
            {/* Your page content */}
            <Heading>Communities Under Management</Heading>
            <Subheading>More to come soon!</Subheading>

            <Divider className="mt-4 mb-6"></Divider>

            <HOAList></HOAList>
        </SidebarLayout>
    );
};

export default HOAPage;
