/* tslint:disable */
/* eslint-disable */
/**
 * Hoa Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HoaCreateOrUpdateSchema
 */
export interface HoaCreateOrUpdateSchema {
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'contact_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'contact_phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'website_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HoaCreateOrUpdateSchema
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface HoaSchema
 */
export interface HoaSchema {
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'contact_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'contact_phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'website_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HoaSchema
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HoaSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'date_updated'?: string | null;
}
/**
 * 
 * @export
 * @interface HomeCreateOrUpdateSchema
 */
export interface HomeCreateOrUpdateSchema {
    /**
     * 
     * @type {Array<OwnerSchema>}
     * @memberof HomeCreateOrUpdateSchema
     */
    'owners': Array<OwnerSchema>;
    /**
     * 
     * @type {number}
     * @memberof HomeCreateOrUpdateSchema
     */
    'hoa_id': number;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'property_type': string;
    /**
     * 
     * @type {string}
     * @memberof HomeCreateOrUpdateSchema
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface HomeSchema
 */
export interface HomeSchema {
    /**
     * 
     * @type {Array<OwnerSchema>}
     * @memberof HomeSchema
     */
    'owners': Array<OwnerSchema>;
    /**
     * 
     * @type {number}
     * @memberof HomeSchema
     */
    'hoa_id': number;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'street2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'property_type': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof HomeSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof HomeSchema
     */
    'date_updated'?: string | null;
}
/**
 * 
 * @export
 * @interface OwnerSchema
 */
export interface OwnerSchema {
    /**
     * 
     * @type {string}
     * @memberof OwnerSchema
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof OwnerSchema
     */
    'last_name': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRootInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRootInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getRootInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootLivenessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootLivenessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootLivenessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootReadinessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootReadinessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootReadinessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootInfo(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getRootInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootLivenessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootReadinessGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRootInfo(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRootInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootLivenessGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootLivenessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootReadinessGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootReadinessGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HoaApi - axios parameter creator
 * @export
 */
export const HoaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Hoa
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoa: async (hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHoa', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoas/hoa/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Hoa
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoa_1: async (hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHoa_1', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoas/hoa/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateHoa: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deactivateHoa', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}/deactivate`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateHoa_2: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deactivateHoa_2', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}/deactivate`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoa: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteHoa', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoa_3: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteHoa_3', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Active Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveHoas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hoas/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Active Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveHoas_4: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hoas/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHoas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hoas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHoas_5: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hoas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoaById: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHoaById', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoaById_6: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHoaById_6', 'hoaId', hoaId)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Hoa
         * @param {number} hoaId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoa: async (hoaId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('updateHoa', 'hoaId', hoaId)
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('updateHoa', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Hoa
         * @param {number} hoaId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoa_7: async (hoaId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('updateHoa_7', 'hoaId', hoaId)
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('updateHoa_7', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoas/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HoaApi - functional programming interface
 * @export
 */
export const HoaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HoaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Hoa
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHoa(hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHoa(hoaCreateOrUpdateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.createHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Hoa
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHoa_1(hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHoa_1(hoaCreateOrUpdateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.createHoa_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateHoa(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateHoa(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.deactivateHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateHoa_2(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateHoa_2(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.deactivateHoa_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHoa(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHoa(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.deleteHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHoa_3(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHoa_3(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.deleteHoa_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Active Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllActiveHoas(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HoaSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllActiveHoas(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getAllActiveHoas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Active Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllActiveHoas_4(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HoaSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllActiveHoas_4(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getAllActiveHoas_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllHoas(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HoaSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllHoas(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getAllHoas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllHoas_5(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HoaSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllHoas_5(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getAllHoas_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHoaById(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHoaById(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getHoaById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHoaById_6(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHoaById_6(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getHoaById_6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Hoa
         * @param {number} hoaId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHoa(hoaId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHoa(hoaId, hoaCreateOrUpdateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.updateHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Hoa
         * @param {number} hoaId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHoa_7(hoaId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHoa_7(hoaId, hoaCreateOrUpdateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.updateHoa_7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HoaApi - factory interface
 * @export
 */
export const HoaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HoaApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Hoa
         * @param {HoaApiCreateHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoa(requestParameters: HoaApiCreateHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.createHoa(requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Hoa
         * @param {HoaApiCreateHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoa_1(requestParameters: HoaApiCreateHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.createHoa_1(requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {HoaApiDeactivateHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateHoa(requestParameters: HoaApiDeactivateHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.deactivateHoa(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deactivate an HOA by its ID.
         * @summary Deactivate Hoa
         * @param {HoaApiDeactivateHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateHoa_2(requestParameters: HoaApiDeactivateHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.deactivateHoa_2(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {HoaApiDeleteHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoa(requestParameters: HoaApiDeleteHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.deleteHoa(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {HoaApiDeleteHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoa_3(requestParameters: HoaApiDeleteHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.deleteHoa_3(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Active Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveHoas(options?: RawAxiosRequestConfig): AxiosPromise<Array<HoaSchema>> {
            return localVarFp.getAllActiveHoas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Active Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveHoas_4(options?: RawAxiosRequestConfig): AxiosPromise<Array<HoaSchema>> {
            return localVarFp.getAllActiveHoas_4(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHoas(options?: RawAxiosRequestConfig): AxiosPromise<Array<HoaSchema>> {
            return localVarFp.getAllHoas(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all HOAs, including both active and inactive, with their detailed address information.
         * @summary Get All Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHoas_5(options?: RawAxiosRequestConfig): AxiosPromise<Array<HoaSchema>> {
            return localVarFp.getAllHoas_5(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Hoa
         * @param {HoaApiGetHoaByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoaById(requestParameters: HoaApiGetHoaByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.getHoaById(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Hoa
         * @param {HoaApiGetHoaById0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoaById_6(requestParameters: HoaApiGetHoaById0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.getHoaById_6(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Hoa
         * @param {HoaApiUpdateHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoa(requestParameters: HoaApiUpdateHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.updateHoa(requestParameters.hoaId, requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Hoa
         * @param {HoaApiUpdateHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoa_7(requestParameters: HoaApiUpdateHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.updateHoa_7(requestParameters.hoaId, requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createHoa operation in HoaApi.
 * @export
 * @interface HoaApiCreateHoaRequest
 */
export interface HoaApiCreateHoaRequest {
    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiCreateHoa
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema
}

/**
 * Request parameters for createHoa_1 operation in HoaApi.
 * @export
 * @interface HoaApiCreateHoa0Request
 */
export interface HoaApiCreateHoa0Request {
    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiCreateHoa0
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema
}

/**
 * Request parameters for deactivateHoa operation in HoaApi.
 * @export
 * @interface HoaApiDeactivateHoaRequest
 */
export interface HoaApiDeactivateHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiDeactivateHoa
     */
    readonly hoaId: number
}

/**
 * Request parameters for deactivateHoa_2 operation in HoaApi.
 * @export
 * @interface HoaApiDeactivateHoa0Request
 */
export interface HoaApiDeactivateHoa0Request {
    /**
     * 
     * @type {number}
     * @memberof HoaApiDeactivateHoa0
     */
    readonly hoaId: number
}

/**
 * Request parameters for deleteHoa operation in HoaApi.
 * @export
 * @interface HoaApiDeleteHoaRequest
 */
export interface HoaApiDeleteHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiDeleteHoa
     */
    readonly hoaId: number
}

/**
 * Request parameters for deleteHoa_3 operation in HoaApi.
 * @export
 * @interface HoaApiDeleteHoa0Request
 */
export interface HoaApiDeleteHoa0Request {
    /**
     * 
     * @type {number}
     * @memberof HoaApiDeleteHoa0
     */
    readonly hoaId: number
}

/**
 * Request parameters for getHoaById operation in HoaApi.
 * @export
 * @interface HoaApiGetHoaByIdRequest
 */
export interface HoaApiGetHoaByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiGetHoaById
     */
    readonly hoaId: number
}

/**
 * Request parameters for getHoaById_6 operation in HoaApi.
 * @export
 * @interface HoaApiGetHoaById0Request
 */
export interface HoaApiGetHoaById0Request {
    /**
     * 
     * @type {number}
     * @memberof HoaApiGetHoaById0
     */
    readonly hoaId: number
}

/**
 * Request parameters for updateHoa operation in HoaApi.
 * @export
 * @interface HoaApiUpdateHoaRequest
 */
export interface HoaApiUpdateHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiUpdateHoa
     */
    readonly hoaId: number

    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiUpdateHoa
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema
}

/**
 * Request parameters for updateHoa_7 operation in HoaApi.
 * @export
 * @interface HoaApiUpdateHoa0Request
 */
export interface HoaApiUpdateHoa0Request {
    /**
     * 
     * @type {number}
     * @memberof HoaApiUpdateHoa0
     */
    readonly hoaId: number

    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiUpdateHoa0
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema
}

/**
 * HoaApi - object-oriented interface
 * @export
 * @class HoaApi
 * @extends {BaseAPI}
 */
export class HoaApi extends BaseAPI {
    /**
     * 
     * @summary Create Hoa
     * @param {HoaApiCreateHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public createHoa(requestParameters: HoaApiCreateHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).createHoa(requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Hoa
     * @param {HoaApiCreateHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public createHoa_1(requestParameters: HoaApiCreateHoa0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).createHoa_1(requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deactivate an HOA by its ID.
     * @summary Deactivate Hoa
     * @param {HoaApiDeactivateHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public deactivateHoa(requestParameters: HoaApiDeactivateHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).deactivateHoa(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deactivate an HOA by its ID.
     * @summary Deactivate Hoa
     * @param {HoaApiDeactivateHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public deactivateHoa_2(requestParameters: HoaApiDeactivateHoa0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).deactivateHoa_2(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Hoa
     * @param {HoaApiDeleteHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public deleteHoa(requestParameters: HoaApiDeleteHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).deleteHoa(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Hoa
     * @param {HoaApiDeleteHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public deleteHoa_3(requestParameters: HoaApiDeleteHoa0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).deleteHoa_3(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Active Hoas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getAllActiveHoas(options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getAllActiveHoas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Active Hoas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getAllActiveHoas_4(options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getAllActiveHoas_4(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all HOAs, including both active and inactive, with their detailed address information.
     * @summary Get All Hoas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getAllHoas(options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getAllHoas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all HOAs, including both active and inactive, with their detailed address information.
     * @summary Get All Hoas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getAllHoas_5(options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getAllHoas_5(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Hoa
     * @param {HoaApiGetHoaByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getHoaById(requestParameters: HoaApiGetHoaByIdRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getHoaById(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Hoa
     * @param {HoaApiGetHoaById0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getHoaById_6(requestParameters: HoaApiGetHoaById0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getHoaById_6(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Hoa
     * @param {HoaApiUpdateHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public updateHoa(requestParameters: HoaApiUpdateHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).updateHoa(requestParameters.hoaId, requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Hoa
     * @param {HoaApiUpdateHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public updateHoa_7(requestParameters: HoaApiUpdateHoa0Request, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).updateHoa_7(requestParameters.hoaId, requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Home
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHome: async (homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHome', 'homeCreateOrUpdateSchema', homeCreateOrUpdateSchema)
            const localVarPath = `/homes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(homeCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Home
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHome_1: async (homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'homeCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHome_1', 'homeCreateOrUpdateSchema', homeCreateOrUpdateSchema)
            const localVarPath = `/homes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(homeCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHome: async (hoaId: number, homeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteHome', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('deleteHome', 'homeId', homeId)
            const localVarPath = `/homes/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHome_2: async (hoaId: number, homeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteHome_2', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('deleteHome_2', 'homeId', homeId)
            const localVarPath = `/homes/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Home By Hoa And Id
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeByHoaAndId: async (hoaId: number, homeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHomeByHoaAndId', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('getHomeByHoaAndId', 'homeId', homeId)
            const localVarPath = `/homes/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Home By Hoa And Id
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeByHoaAndId_3: async (hoaId: number, homeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHomeByHoaAndId_3', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('getHomeByHoaAndId_3', 'homeId', homeId)
            const localVarPath = `/homes/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByHoa: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHomesByHoa', 'hoaId', hoaId)
            const localVarPath = `/homes/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByHoa_4: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHomesByHoa_4', 'hoaId', hoaId)
            const localVarPath = `/homes/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHome: async (hoaId: number, homeId: number, homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('updateHome', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('updateHome', 'homeId', homeId)
            // verify required parameter 'homeCreateOrUpdateSchema' is not null or undefined
            assertParamExists('updateHome', 'homeCreateOrUpdateSchema', homeCreateOrUpdateSchema)
            const localVarPath = `/homes/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(homeCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHome_5: async (hoaId: number, homeId: number, homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('updateHome_5', 'hoaId', hoaId)
            // verify required parameter 'homeId' is not null or undefined
            assertParamExists('updateHome_5', 'homeId', homeId)
            // verify required parameter 'homeCreateOrUpdateSchema' is not null or undefined
            assertParamExists('updateHome_5', 'homeCreateOrUpdateSchema', homeCreateOrUpdateSchema)
            const localVarPath = `/homes/{hoa_id}/{home_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)))
                .replace(`{${"home_id"}}`, encodeURIComponent(String(homeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(homeCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HomeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Home
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHome(homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHome(homeCreateOrUpdateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.createHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Home
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHome_1(homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHome_1(homeCreateOrUpdateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.createHome_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHome(hoaId: number, homeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHome(hoaId, homeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.deleteHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHome_2(hoaId: number, homeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHome_2(hoaId, homeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.deleteHome_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Home By Hoa And Id
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeByHoaAndId(hoaId: number, homeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeByHoaAndId(hoaId, homeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.getHomeByHoaAndId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Home By Hoa And Id
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeByHoaAndId_3(hoaId: number, homeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeByHoaAndId_3(hoaId, homeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.getHomeByHoaAndId_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomesByHoa(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HomeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomesByHoa(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.getHomesByHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomesByHoa_4(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HomeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomesByHoa_4(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.getHomesByHoa_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHome(hoaId: number, homeId: number, homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHome(hoaId, homeId, homeCreateOrUpdateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.updateHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Home
         * @param {number} hoaId 
         * @param {number} homeId 
         * @param {HomeCreateOrUpdateSchema} homeCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHome_5(hoaId: number, homeId: number, homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHome_5(hoaId, homeId, homeCreateOrUpdateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HomeApi.updateHome_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HomeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Home
         * @param {HomeApiCreateHomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHome(requestParameters: HomeApiCreateHomeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.createHome(requestParameters.homeCreateOrUpdateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Home
         * @param {HomeApiCreateHome0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHome_1(requestParameters: HomeApiCreateHome0Request, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.createHome_1(requestParameters.homeCreateOrUpdateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Home
         * @param {HomeApiDeleteHomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHome(requestParameters: HomeApiDeleteHomeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.deleteHome(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Home
         * @param {HomeApiDeleteHome0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHome_2(requestParameters: HomeApiDeleteHome0Request, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.deleteHome_2(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Home By Hoa And Id
         * @param {HomeApiGetHomeByHoaAndIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeByHoaAndId(requestParameters: HomeApiGetHomeByHoaAndIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.getHomeByHoaAndId(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Home By Hoa And Id
         * @param {HomeApiGetHomeByHoaAndId0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeByHoaAndId_3(requestParameters: HomeApiGetHomeByHoaAndId0Request, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.getHomeByHoaAndId_3(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {HomeApiGetHomesByHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByHoa(requestParameters: HomeApiGetHomesByHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<HomeSchema>> {
            return localVarFp.getHomesByHoa(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Homes By Hoa
         * @param {HomeApiGetHomesByHoa0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomesByHoa_4(requestParameters: HomeApiGetHomesByHoa0Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<HomeSchema>> {
            return localVarFp.getHomesByHoa_4(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Home
         * @param {HomeApiUpdateHomeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHome(requestParameters: HomeApiUpdateHomeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.updateHome(requestParameters.hoaId, requestParameters.homeId, requestParameters.homeCreateOrUpdateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Home
         * @param {HomeApiUpdateHome0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHome_5(requestParameters: HomeApiUpdateHome0Request, options?: RawAxiosRequestConfig): AxiosPromise<HomeSchema> {
            return localVarFp.updateHome_5(requestParameters.hoaId, requestParameters.homeId, requestParameters.homeCreateOrUpdateSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createHome operation in HomeApi.
 * @export
 * @interface HomeApiCreateHomeRequest
 */
export interface HomeApiCreateHomeRequest {
    /**
     * 
     * @type {HomeCreateOrUpdateSchema}
     * @memberof HomeApiCreateHome
     */
    readonly homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema
}

/**
 * Request parameters for createHome_1 operation in HomeApi.
 * @export
 * @interface HomeApiCreateHome0Request
 */
export interface HomeApiCreateHome0Request {
    /**
     * 
     * @type {HomeCreateOrUpdateSchema}
     * @memberof HomeApiCreateHome0
     */
    readonly homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema
}

/**
 * Request parameters for deleteHome operation in HomeApi.
 * @export
 * @interface HomeApiDeleteHomeRequest
 */
export interface HomeApiDeleteHomeRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiDeleteHome
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiDeleteHome
     */
    readonly homeId: number
}

/**
 * Request parameters for deleteHome_2 operation in HomeApi.
 * @export
 * @interface HomeApiDeleteHome0Request
 */
export interface HomeApiDeleteHome0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiDeleteHome0
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiDeleteHome0
     */
    readonly homeId: number
}

/**
 * Request parameters for getHomeByHoaAndId operation in HomeApi.
 * @export
 * @interface HomeApiGetHomeByHoaAndIdRequest
 */
export interface HomeApiGetHomeByHoaAndIdRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomeByHoaAndId
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomeByHoaAndId
     */
    readonly homeId: number
}

/**
 * Request parameters for getHomeByHoaAndId_3 operation in HomeApi.
 * @export
 * @interface HomeApiGetHomeByHoaAndId0Request
 */
export interface HomeApiGetHomeByHoaAndId0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomeByHoaAndId0
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomeByHoaAndId0
     */
    readonly homeId: number
}

/**
 * Request parameters for getHomesByHoa operation in HomeApi.
 * @export
 * @interface HomeApiGetHomesByHoaRequest
 */
export interface HomeApiGetHomesByHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomesByHoa
     */
    readonly hoaId: number
}

/**
 * Request parameters for getHomesByHoa_4 operation in HomeApi.
 * @export
 * @interface HomeApiGetHomesByHoa0Request
 */
export interface HomeApiGetHomesByHoa0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiGetHomesByHoa0
     */
    readonly hoaId: number
}

/**
 * Request parameters for updateHome operation in HomeApi.
 * @export
 * @interface HomeApiUpdateHomeRequest
 */
export interface HomeApiUpdateHomeRequest {
    /**
     * 
     * @type {number}
     * @memberof HomeApiUpdateHome
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiUpdateHome
     */
    readonly homeId: number

    /**
     * 
     * @type {HomeCreateOrUpdateSchema}
     * @memberof HomeApiUpdateHome
     */
    readonly homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema
}

/**
 * Request parameters for updateHome_5 operation in HomeApi.
 * @export
 * @interface HomeApiUpdateHome0Request
 */
export interface HomeApiUpdateHome0Request {
    /**
     * 
     * @type {number}
     * @memberof HomeApiUpdateHome0
     */
    readonly hoaId: number

    /**
     * 
     * @type {number}
     * @memberof HomeApiUpdateHome0
     */
    readonly homeId: number

    /**
     * 
     * @type {HomeCreateOrUpdateSchema}
     * @memberof HomeApiUpdateHome0
     */
    readonly homeCreateOrUpdateSchema: HomeCreateOrUpdateSchema
}

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI {
    /**
     * 
     * @summary Create Home
     * @param {HomeApiCreateHomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public createHome(requestParameters: HomeApiCreateHomeRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).createHome(requestParameters.homeCreateOrUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Home
     * @param {HomeApiCreateHome0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public createHome_1(requestParameters: HomeApiCreateHome0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).createHome_1(requestParameters.homeCreateOrUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Home
     * @param {HomeApiDeleteHomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public deleteHome(requestParameters: HomeApiDeleteHomeRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).deleteHome(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Home
     * @param {HomeApiDeleteHome0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public deleteHome_2(requestParameters: HomeApiDeleteHome0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).deleteHome_2(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Home By Hoa And Id
     * @param {HomeApiGetHomeByHoaAndIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomeByHoaAndId(requestParameters: HomeApiGetHomeByHoaAndIdRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomeByHoaAndId(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Home By Hoa And Id
     * @param {HomeApiGetHomeByHoaAndId0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomeByHoaAndId_3(requestParameters: HomeApiGetHomeByHoaAndId0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomeByHoaAndId_3(requestParameters.hoaId, requestParameters.homeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Homes By Hoa
     * @param {HomeApiGetHomesByHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomesByHoa(requestParameters: HomeApiGetHomesByHoaRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomesByHoa(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Homes By Hoa
     * @param {HomeApiGetHomesByHoa0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomesByHoa_4(requestParameters: HomeApiGetHomesByHoa0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomesByHoa_4(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Home
     * @param {HomeApiUpdateHomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public updateHome(requestParameters: HomeApiUpdateHomeRequest, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).updateHome(requestParameters.hoaId, requestParameters.homeId, requestParameters.homeCreateOrUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Home
     * @param {HomeApiUpdateHome0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public updateHome_5(requestParameters: HomeApiUpdateHome0Request, options?: RawAxiosRequestConfig) {
        return HomeApiFp(this.configuration).updateHome_5(requestParameters.hoaId, requestParameters.homeId, requestParameters.homeCreateOrUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



