import AppSidebar from './AppSidebar';
import { Navbar } from './Components/Catalyst/navbar';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import { Heading, Subheading } from './Components/Catalyst/heading';
import { Divider } from './Components/Catalyst/divider';
import ViolationList from './ViolationList';

interface ViolationPageProps {
    currentPage: string; // Keep this prop as it's needed for sidebar navigation
}

const ViolationPage: React.FC<ViolationPageProps> = ({ currentPage }) => {
    return (
        <SidebarLayout
            sidebar={<AppSidebar currentPage={currentPage} />}
            navbar={<Navbar>{/* Your navbar content */}</Navbar>}
        >
            {/* Your page content */}
            <Heading>All Violations</Heading>
            <Subheading>More to come soon!</Subheading>

            <Divider className="mt-4 mb-6"></Divider>

            <ViolationList></ViolationList>
        </SidebarLayout>
    );
};

export default ViolationPage;
