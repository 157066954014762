import React, { useEffect, useState } from 'react';
import {
    Sidebar,
    SidebarSection,
    SidebarItem,
    SidebarHeader,
    SidebarBody,
    SidebarFooter,
    SidebarSpacer,
} from './Components/Catalyst/sidebar';
import {
    HomeIcon,
    ChartBarIcon,
    CogIcon,
    HomeModernIcon,
    ChevronUpIcon,
    UserIcon,
    Cog8ToothIcon,
    ShieldCheckIcon,
    LightBulbIcon,
    ArrowRightStartOnRectangleIcon,
    ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/outline';
import logo from './assets/logo.png';
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from './Components/Catalyst/dropdown';
import { Avatar } from './Components/Catalyst/avatar';
import {
    clearAllTokens,
    clearRememberMe,
    clearSessionStorage,
    clearUserName,
    generateInitials,
    getUserEmailFromToken,
    getUserNameFromToken,
} from './TokenUtils';
import { useNavigate } from 'react-router-dom';

interface AppsidebarProps {
    currentPage: string;
}

const AppSidebar: React.FC<AppsidebarProps> = ({ currentPage }) => {
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState<string | null>(null);
    const [userName, setUserName] = useState<string | null>(null);
    const [userInitials, setUserInitials] = useState<string>('');

    useEffect(() => {
        const email = getUserEmailFromToken();
        const name = getUserNameFromToken();
        setUserEmail(email);
        setUserName(name);
        if (name) {
            setUserInitials(generateInitials(name));
        }
    }, []);

    const handleLogout = () => {
        clearAllTokens();
        clearUserName();
        clearRememberMe();
        clearSessionStorage();
        navigate('/login');
    };

    return (
        <Sidebar>
            <SidebarHeader>
                <div className="flex items-center space-x-3 mb-4">
                    <img
                        src={logo}
                        alt="Dashboard Logo"
                        className="h-8 w-auto"
                    />
                    <h2 className="text-xl font-bold text-zinc-900 dark:text-white">
                        Levittown
                    </h2>
                </div>
            </SidebarHeader>
            <SidebarBody>
                <SidebarSection>
                    <SidebarItem href="/home" current={currentPage === 'home'}>
                        <HomeIcon className="h-5 w-5" />
                        <span>Home</span>
                    </SidebarItem>
                    <SidebarItem href="/hoas" current={currentPage === 'hoas'}>
                        <HomeModernIcon className="h-5 w-5" />
                        <span>HOAs</span>
                    </SidebarItem>
                    <SidebarItem
                        href="/violations"
                        current={currentPage === 'violations'}
                    >
                        <ChartBarIcon className="h-5 w-5" />
                        <span>Violations</span>
                    </SidebarItem>
                    <SidebarItem
                        href="/dashboard/settings"
                        current={currentPage === 'settings'}
                    >
                        <CogIcon className="h-5 w-5" />
                        <span>Settings</span>
                    </SidebarItem>
                </SidebarSection>

                <SidebarSpacer />

                <SidebarSection className="border-t border-zinc-200 dark:border-zinc-700 pt-4">
                    <SidebarItem onClick={handleLogout}>
                        <ArrowRightStartOnRectangleIcon className="h-5 w-5" />
                        <span>Sign out</span>
                    </SidebarItem>
                </SidebarSection>
            </SidebarBody>
            <SidebarFooter className="max-lg:hidden">
                <Dropdown>
                    <DropdownButton as={SidebarItem}>
                        <span className="flex min-w-0 items-center gap-3">
                            <Avatar
                                // TODO: add profile pic
                                // src="/profile-photo.jpg"
                                initials={userInitials}
                                className="size-10"
                                square
                                alt=""
                            />
                            <span className="min-w-0">
                                <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                                    {userName || 'User'}
                                </span>
                                <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                                    {userEmail || 'No email provided'}
                                </span>
                            </span>
                        </span>
                        <ChevronUpIcon className="h-5 w-5" />
                    </DropdownButton>
                    <DropdownMenu className="min-w-64" anchor="top start">
                        <DropdownItem href="/my-profile">
                            <UserIcon className="h-5 w-5" />
                            <DropdownLabel>My profile</DropdownLabel>
                        </DropdownItem>
                        <DropdownItem href="/settings">
                            <Cog8ToothIcon className="h-5 w-5" />
                            <DropdownLabel>Settings</DropdownLabel>
                        </DropdownItem>
                        <DropdownDivider />
                        <DropdownItem href="/privacy-policy">
                            <ShieldCheckIcon className="h-5 w-5" />
                            <DropdownLabel>Privacy policy</DropdownLabel>
                        </DropdownItem>
                        <DropdownItem href="/share-feedback">
                            <LightBulbIcon className="h-5 w-5" />
                            <DropdownLabel>Share feedback</DropdownLabel>
                        </DropdownItem>
                        <DropdownDivider />
                        <DropdownItem href="/logout" onClick={handleLogout}>
                            <ArrowRightStartOnRectangleIcon className="h-5 w-5" />
                            <DropdownLabel>Sign out</DropdownLabel>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </SidebarFooter>
        </Sidebar>
    );
};

export default AppSidebar;
