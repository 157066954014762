import React, { useState, useEffect } from 'react';
import logo from './assets/logo.png';
import { authService } from './ApiClients';
import {
    getToken,
    verifyToken,
    getUserName,
    clearToken,
    clearUserName,
    clearRememberMe,
    getRememberMe,
} from './TokenUtils';

const LoginPage: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const verifyAuth = async () => {
            let token = getToken();
            let userName = getUserName();
            let rememberMe = getRememberMe();
            if (!token) {
                setIsAuthenticated(false);
                clearToken();
                if (userName && rememberMe) {
                    setRememberMe(true);
                    setUsername(userName);
                } else {
                    clearRememberMe();
                    clearUserName();
                }
            } else {
                try {
                    const verified = await verifyToken(token);
                    if (verified) {
                        window.location.href = '/home';
                        return;
                    }
                    setIsAuthenticated(verified);
                    if (userName && rememberMe) {
                        setRememberMe(true);
                        setUsername(userName);
                    } else {
                        setRememberMe(false);
                        setUsername('');
                    }
                } catch (error) {
                    console.error('Token verification failed:', error);
                    setIsAuthenticated(false);
                }
            }
        };

        verifyAuth();
    }, []);

    const validateEmail = (email: string): boolean => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePassword = (password: string): boolean => {
        return password.length >= 6;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');

        if (!validateEmail(username)) {
            setError('Please enter a valid email address.');
            return;
        }

        if (!validatePassword(password)) {
            setError('Password must be at least 8 characters long.');
            return;
        }

        try {
            const response = await authService.login({
                username,
                password,
                grantType: 'password',
            });

            const responseData = response.data as {
                access_token?: string;
                refresh_token?: string;
            };

            if (!responseData.access_token) {
                throw new Error('Access token not found in response');
            }

            const token = responseData.access_token;
            const refreshToken = responseData.refresh_token;

            if (rememberMe) {
                localStorage.setItem('token', token);
                localStorage.setItem('username', username);
                localStorage.setItem('rememberme', rememberMe.toString());
                if (refreshToken) {
                    localStorage.setItem('refreshToken', refreshToken);
                }
            } else {
                clearToken();
                clearUserName();
                clearRememberMe();
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('username', username);
                sessionStorage.setItem('rememberme', rememberMe.toString());
                if (refreshToken) {
                    sessionStorage.setItem('refreshToken', refreshToken);
                }
            }

            window.location.href = '/home';
        } catch (error: any) {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        setError(
                            'Invalid email or password. Please try again.'
                        );
                        break;
                    case 403:
                        setError(
                            'Your account has been locked. Please contact support.'
                        );
                        break;
                    case 404:
                        setError(
                            'Login service not found. Please try again later.'
                        );
                        break;
                    case 500:
                        setError('Server error. Please try again later.');
                        break;
                    default:
                        setError(
                            `An error occurred (${error.response.status}). Please try again.`
                        );
                }
            } else if (error.request) {
                setError(
                    'No response from server. Please check your internet connection and try again.'
                );
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
            console.error('login failed: ', error);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center py-8 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    alt="Levittown"
                    src={logo}
                    className="mx-auto h-[100px] w-auto"
                />
                <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
                    Sign in to your account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 dark:bg-gray-800">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                            >
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="email"
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-700 dark:text-white dark:ring-gray-600 dark:placeholder:text-gray-400"
                                />
                            </div>
                        </div>

                        <div>
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                            >
                                Password
                            </label>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-700 dark:text-white dark:ring-gray-600 dark:placeholder:text-gray-400"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    checked={rememberMe}
                                    onChange={(e) =>
                                        setRememberMe(e.target.checked)
                                    }
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label
                                    htmlFor="remember-me"
                                    className="ml-3 block text-sm leading-6 text-gray-900 dark:text-white"
                                >
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm leading-6">
                                <a
                                    href="/forgot-password"
                                    className="font-semibold text-indigo-600 hover:text-indigo-500 dark:text-indigo-200"
                                >
                                    Forgot password?
                                </a>
                            </div>
                        </div>

                        <div>
                            {error && (
                                <div
                                    className="mb-4 p-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                                    role="alert"
                                >
                                    {error}
                                </div>
                            )}
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Sign in
                            </button>
                        </div>
                    </form>

                    <div>
                        <div className="relative mt-10">
                            <div
                                aria-hidden="true"
                                className="absolute inset-0 flex items-center"
                            >
                                <div className="w-full border-t border-gray-200" />
                            </div>
                            <div className="relative flex justify-center text-sm font-medium leading-6">
                                <span className="bg-white px-6 text-gray-900">
                                    Or continue with
                                </span>
                            </div>
                        </div>

                        <div className="mt-6 grid grid-cols-1 gap-4">
                            <a
                                href="#"
                                className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent dark:bg-black dark:text-white dark:ring-gray-600 dark:hover:bg-gray-900"
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                >
                                    <path
                                        d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                                        fill="#EA4335"
                                    />
                                    <path
                                        d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                                        fill="#4285F4"
                                    />
                                    <path
                                        d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                                        fill="#FBBC05"
                                    />
                                    <path
                                        d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                                        fill="#34A853"
                                    />
                                </svg>
                                <span className="text-sm font-semibold leading-6">
                                    Google
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
