import React, { useState, useEffect } from 'react';
import { Heading } from './Components/Catalyst/heading';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from './Components/Catalyst/table';
import { HoaCreateOrUpdateSchema, HoaSchema } from './hoa-api-client';
import { hoaService } from './ApiClients';
import { Input, InputGroup } from './Components/Catalyst/input';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Button } from './Components/Catalyst/button';
import {
    Dialog,
    DialogTitle,
    DialogBody,
    DialogActions,
} from './Components/Catalyst/dialog';
import {
    Description,
    ErrorMessage,
    Field,
    FieldGroup,
    Fieldset,
    Label,
    Legend,
} from './Components/Catalyst/fieldset';
import { Textarea } from './Components/Catalyst/textarea';
import { useNavigate } from 'react-router-dom';

interface FormErrors {
    name?: string;
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    contact_phone?: string;
    contact_email?: string;
    website_url?: string;
}

const initialHoaState: HoaCreateOrUpdateSchema = {
    name: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    contact_email: '',
    contact_phone: '',
    website_url: '',
    description: '',
    is_active: true,
};

interface TouchedFields {
    [key: string]: boolean;
}

const HOAList: React.FC = () => {
    const navigate = useNavigate();
    const [hoas, setHoas] = useState<HoaSchema[]>([]);
    const [filteredHoas, setFilteredHoas] = useState<HoaSchema[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newHoa, setNewHoa] =
        useState<HoaCreateOrUpdateSchema>(initialHoaState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [touchedFields, setTouchedFields] = useState<TouchedFields>({});

    useEffect(() => {
        const fetchHoas = async () => {
            try {
                setLoading(true);
                const response = await hoaService.getAllActiveHoas();
                setHoas(response.data);
                setFilteredHoas(response.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching HOAs:', err);
                setError('Failed to fetch HOAs. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchHoas();
    }, []);

    useEffect(() => {
        const filtered = hoas.filter(
            (hoa) =>
                hoa.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                getFullAddress(hoa)
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
        );
        setFilteredHoas(filtered);
    }, [searchTerm, hoas]);

    const getFullAddress = (hoa: HoaSchema): string => {
        const parts = [
            hoa.street,
            hoa.street2,
            hoa.city,
            hoa.state,
            hoa.zip,
            hoa.country,
        ].filter(Boolean);
        return parts.join(', ');
    };

    const handleRowClick = (hoa: HoaSchema) => {
        navigate(`/hoa/${hoa.id}`);
    };

    const handleAddHoa = () => {
        setIsDialogOpen(true);
    };

    const resetForm = () => {
        setNewHoa(initialHoaState);
        setFormErrors({});
        setTouchedFields({});
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        resetForm();
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const validateField = (
        name: keyof HoaCreateOrUpdateSchema,
        value: string | boolean | null | undefined
    ): string | undefined => {
        let error: string | undefined;
        switch (name) {
            case 'name':
            case 'street':
            case 'city':
            case 'state':
            case 'zip':
                error = !value ? 'Name is required' : undefined;
                break;
            case 'contact_phone':
                if (!value || value === '') {
                    return 'Contact phone is required';
                }
                return typeof value === 'string' && !/^\d{10,11}$/.test(value)
                    ? 'Phone number must be 10 or 11 digits'
                    : undefined;
            case 'contact_email':
                if (!value || value === '') {
                    return 'Contact email is required';
                }
                return typeof value === 'string' &&
                    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
                    ? 'Invalid email format'
                    : undefined;
            case 'website_url':
                error =
                    value &&
                    typeof value === 'string' &&
                    !/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
                        value
                    )
                        ? 'Invalid website URL format'
                        : undefined;
                break;
            // Add cases for other fields if necessary
        }
        return error;
    };

    const handleBlur = (
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setTouchedFields((prev) => ({ ...prev, [name]: true }));

        // Validate the field on blur
        const error = validateField(
            name as keyof HoaCreateOrUpdateSchema,
            value
        );
        setFormErrors((prev) => ({ ...prev, [name]: error }));
    };

    const validateForm = (): boolean => {
        const errors: FormErrors = {};
        (Object.keys(newHoa) as Array<keyof HoaCreateOrUpdateSchema>).forEach(
            (key) => {
                validateField(key, newHoa[key]);
                if (formErrors[key as keyof FormErrors]) {
                    errors[key as keyof FormErrors] =
                        formErrors[key as keyof FormErrors];
                }
            }
        );
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setNewHoa((prev) => ({ ...prev, [name]: value }));
        setTouchedFields((prev) => ({ ...prev, [name]: true }));

        // Validate the field immediately
        const error = validateField(
            name as keyof HoaCreateOrUpdateSchema,
            value
        );

        setFormErrors((prev) => {
            const newErrors = { ...prev };
            if (error) {
                // If there's an error, add or update it
                newErrors[name as keyof FormErrors] = error;
            } else {
                // If there's no error, remove the field from formErrors
                delete newErrors[name as keyof FormErrors];
            }
            return newErrors;
        });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setNewHoa((prev) => ({ ...prev, [name]: checked }));
    };

    const handleSaveHoa = async () => {
        if (validateForm()) {
            try {
                const response = await hoaService.createHoa({
                    hoaCreateOrUpdateSchema: newHoa,
                });
                console.log('New HOA created:', response.data);
                setHoas((prevHoas) => [...prevHoas, response.data]);
                handleDialogClose();
            } catch (err) {
                console.error('Error creating HOA:', err);
                // Handle error (e.g., show error message to user)
            }
        }
    };

    if (loading) {
        return <div>Loading HOAs...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <>
            <div className="flex justify-between items-center mb-4">
                <Heading level={4}>HOA List</Heading>
                <div className="flex items-center space-x-4">
                    <Button onClick={handleAddHoa}>
                        <PlusIcon className="h-5 w-5 mr-2" />
                        Add HOA
                    </Button>
                    <div className="w-64">
                        <InputGroup>
                            <MagnifyingGlassIcon data-slot="icon" />
                            <Input
                                type="search"
                                placeholder="Search by name or address..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </InputGroup>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-zinc-800 rounded-lg shadow transition-colors duration-200 overflow-hidden">
                <div className="px-4 py-5 sm:p-6">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full align-middle">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <div className="max-h-[500px] overflow-y-auto">
                                    <Table>
                                        <TableHead className="sticky top-0 z-10 bg-white dark:bg-zinc-800">
                                            <TableRow>
                                                <TableHeader>Name</TableHeader>
                                                <TableHeader>
                                                    Address
                                                </TableHeader>
                                                <TableHeader>
                                                    Contact Email
                                                </TableHeader>
                                                <TableHeader>
                                                    Contact Phone
                                                </TableHeader>
                                                <TableHeader>
                                                    Status
                                                </TableHeader>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredHoas.map((hoa) => (
                                                <TableRow
                                                    key={hoa.id}
                                                    onClick={() =>
                                                        handleRowClick(hoa)
                                                    }
                                                    className="cursor-pointer hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors duration-150"
                                                >
                                                    <TableCell>
                                                        {hoa.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {getFullAddress(hoa)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {hoa.contact_email}
                                                    </TableCell>
                                                    <TableCell>
                                                        {hoa.contact_phone}
                                                    </TableCell>
                                                    <TableCell>
                                                        <span
                                                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                                hoa.is_active
                                                                    ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                                                                    : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
                                                            }`}
                                                        >
                                                            {hoa.is_active
                                                                ? 'Active'
                                                                : 'Inactive'}
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Add New HOA</DialogTitle>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label htmlFor="name">HOA Name</Label>
                                <Description>required</Description>
                                <Input
                                    id="name"
                                    name="name"
                                    value={newHoa.name}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter HOA name"
                                    required
                                    data-invalid={
                                        touchedFields.name && formErrors.name
                                    }
                                />
                                {touchedFields.name && formErrors.name && (
                                    <ErrorMessage>
                                        {formErrors.name}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="street">Street</Label>
                                <Description>required</Description>
                                <Input
                                    id="street"
                                    name="street"
                                    value={newHoa.street}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter street address"
                                    required
                                    data-invalid={
                                        touchedFields.street &&
                                        formErrors.street
                                    }
                                />
                                {touchedFields.street && formErrors.street && (
                                    <ErrorMessage>
                                        {formErrors.street}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="street2">Street 2</Label>
                                <Input
                                    id="street2"
                                    name="street2"
                                    value={newHoa.street2 || ''}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter additional street information (optional)"
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="city">City</Label>
                                <Description>required</Description>
                                <Input
                                    id="city"
                                    name="city"
                                    value={newHoa.city}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter city"
                                    required
                                    data-invalid={
                                        touchedFields.city && formErrors.city
                                    }
                                />
                                {touchedFields.city && formErrors.city && (
                                    <ErrorMessage>
                                        {formErrors.city}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="state">State</Label>
                                <Description>required</Description>
                                <Input
                                    id="state"
                                    name="state"
                                    value={newHoa.state}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter state"
                                    required
                                    data-invalid={
                                        touchedFields.state && formErrors.state
                                    }
                                />
                                {touchedFields.state && formErrors.state && (
                                    <ErrorMessage>
                                        {formErrors.state}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="zip">ZIP Code</Label>
                                <Description>required</Description>
                                <Input
                                    id="zip"
                                    name="zip"
                                    value={newHoa.zip}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter ZIP code"
                                    required
                                    data-invalid={
                                        touchedFields.zip && formErrors.zip
                                    }
                                />
                                {touchedFields.zip && formErrors.zip && (
                                    <ErrorMessage>
                                        {formErrors.zip}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="country">Country</Label>
                                <Input
                                    id="country"
                                    name="country"
                                    value={newHoa.country || ''}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter country"
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="contact_email">
                                    Contact Email
                                </Label>
                                <Description>required</Description>
                                <Input
                                    id="contact_email"
                                    name="contact_email"
                                    type="email"
                                    value={newHoa.contact_email || ''}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter contact email"
                                    data-invalid={
                                        touchedFields.contact_email &&
                                        formErrors.contact_email
                                    }
                                />
                                {touchedFields.contact_email &&
                                    formErrors.contact_email && (
                                        <ErrorMessage>
                                            {formErrors.contact_email}
                                        </ErrorMessage>
                                    )}
                            </Field>
                            <Field>
                                <Label htmlFor="contact_phone">
                                    Contact Phone
                                </Label>
                                <Description>required</Description>
                                <Input
                                    id="contact_phone"
                                    name="contact_phone"
                                    type="tel"
                                    value={newHoa.contact_phone || ''}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter contact phone"
                                    data-invalid={
                                        touchedFields.contact_phone &&
                                        formErrors.contact_phone
                                    }
                                />
                                {touchedFields.contact_phone &&
                                    formErrors.contact_phone && (
                                        <ErrorMessage>
                                            {formErrors.contact_phone}
                                        </ErrorMessage>
                                    )}
                            </Field>
                            <Field>
                                <Label htmlFor="website_url">Website URL</Label>
                                <Input
                                    id="website_url"
                                    name="website_url"
                                    type="url"
                                    value={newHoa.website_url || ''}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter website URL"
                                    data-invalid={
                                        touchedFields.website_url &&
                                        formErrors.website_url
                                    }
                                />
                                {touchedFields.website_url &&
                                    formErrors.website_url && (
                                        <ErrorMessage>
                                            {formErrors.website_url}
                                        </ErrorMessage>
                                    )}
                            </Field>
                            <Field>
                                <Label htmlFor="description">Description</Label>
                                <Description>
                                    Provide a brief description of the HOA
                                </Description>
                                <Textarea
                                    id="description"
                                    name="description"
                                    value={newHoa.description || ''}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter HOA description"
                                    rows={3}
                                />
                            </Field>
                            <Field>
                                <div className="flex items-center">
                                    <input
                                        id="is_active"
                                        type="checkbox"
                                        name="is_active"
                                        checked={newHoa.is_active}
                                        onChange={handleCheckboxChange}
                                        className="mr-2"
                                    />
                                    <Label htmlFor="is_active">Is Active</Label>
                                </div>
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="zinc">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveHoa} color="blue">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default HOAList;
