/**
 * Formats a date string into a more readable format
 * @param dateString - ISO date string or any valid date string
 * @returns Formatted date string
 */
export const formatDate = (dateString: string | null | undefined): string => {
    if (!dateString) return '-'; // or '' if you prefer empty string, or 'N/A'

    try {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }).format(date);
    } catch (error) {
        console.error('Error formatting date:', error);
        return dateString; // Return original string if parsing fails
    }
};

/**
 * Checks if a date string is valid
 * @param dateString - Date string to validate
 * @returns boolean indicating if the date is valid
 */
export const isValidDate = (dateString: string): boolean => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
};

/**
 * Formats a date to ISO string format (YYYY-MM-DD)
 * @param date - Date object or date string
 * @returns ISO formatted date string
 */
export const toISODateString = (date: Date | string): string => {
    const d = typeof date === 'string' ? new Date(date) : date;
    return d.toISOString().split('T')[0];
};
